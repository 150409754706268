export default {
	TYPES: [
		[281, 'Illness'],
		[282, 'Doctor appointment'],
		[283, 'Family emergency'],
		[284, 'Other'],
		[285, 'Tardy'],
		[286, 'No call no show'],
		[336, 'Tardy 1 Point'],
		[337, 'Absence With Call 2 points'],
		[338, 'No Call No Show 3 Points']
	]
}