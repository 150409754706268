<template>
    <div>
        <h1>{{ state.object.data.name }}</h1>
        <h4 class="title_subhead">
            <span>
                {{ state.object.data.shift }}
                {{ state.object.data.cdi_location }}
            </span>
            <span>{{ state.object.data.supervisor }}</span>
        </h4>

        <AddNote v-if="isCurrentView('addNote')" @update="load" />
        <AddAbsence v-else-if="isCurrentView('addAbsence')" @update="load" />
        <History v-else-if="isCurrentView('history')" :history="state.object.data.history" />
        <div v-else>
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Week Ending</label>
                    <select class="form-control" @change="load" v-model="chosenWeek">
                        <option v-for="week in this.weekArray" v-bind:key="week" :value="week">{{ week }}</option>
                    </select>
                </div>
            </div>

            <Week :data="state.object.data" :chosenWeek="this.chosenWeek" @update="load" />
        </div>

        <div v-if="isCurrentView('toPDF') || isCurrentView('toCSV')">
            <Modal @close="closeTimecardOutput" heading="Timecard output">
                <template v-slot:body>
                    <div class="form-group">
                        <label>Date From</label>
                        <DatePicker v-model="timeCardOutputFrom" :input-attributes="{ class: 'form-control' }" />
                    </div>
                    <div class="form-group">
                        <label>Date To</label>
                        <DatePicker v-model="timeCardOutputTo" :input-attributes="{ class: 'form-control' }" />
                    </div>

                    <div class="form-group">
                        <button class="btn btn-primary" @click.prevent="timecardOutput">
                            View
                        </button>
                    </div>
                </template>
            </Modal>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import { timecardStore } from "./Timecard.store";
    import helpers from "@/store/helpers";
    import Week from "@/components/businessObjects/timecard/Week";
    import History from "@/components/businessObjects/utils/History";
    import AddNote from "@/components/businessObjects/timecard/AddNote";
    import AddAbsence from "@/components/businessObjects/timecard/AddAbsence";
    import Modal from "@/components/utils/Modal";
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "Container",
        props: ["id"],
        data() {
            return {
                state: store.state,
                timecardState: timecardStore.state,
                weekArray: [],
                chosenWeek: null,
                timeCardOutputFrom: null,
                timeCardOutputTo: null,
                loadBit: null,
            }
        },
        components: {
            DatePicker,
            Modal,
            AddAbsence,
            AddNote,
            History,
            Week
        },
        created() {
            if (!this.id || this.id == 0) {
                this.$router.push(`/timecard/${ this.$appStore.state.user.userID }`)
            }
            store.initialize(this.timecardState);
            store.api('get_report_weeks_list').then(result => {
                this.weekArray = result;
                this.chosenWeek = this.weekArray[0];
                this.loadBit = '1';
                this.load();
            });
        },
        methods: {
            load: function () {
                if (this.id) {
                    store.state.object.id = this.id;
                    store.state.currentView = 'timecard';
                    if (this.loadBit) {
                        if (!this.weekArray.includes(this.chosenWeek)) {
                            this.$appStore.errorBox('Invalid date; select a new one from the dropdown');

                            return;
                        }
                    }
                    let options = {
                        'params[time_in_to]': this.chosenWeek
                    };

                    store.apiWithObjectLoad(`times`, options);
                }
            },
            closeTimecardOutput: function () {
                this.state.currentView = 'timecard';
            },
            timecardOutput: function () {
                let dateFrom = this.timeCardOutputFrom;
                let dateTo = this.timeCardOutputTo;
                let employeeID = this.id;

                if (!dateFrom || !dateTo) {
                    this.$appStore.errorBox('Must enter a date from and a date to before printing a CSV');

                    return;
                }

                let options = {
                    'params[date_from]': dateFrom,
                    'params[date_to]': dateTo,
                    'params[user_id]': employeeID
                };

                let type = null;
                switch (this.state.currentView) {
                    case 'toPDF':
                        type = 'stream';
                        break;
                    case 'toCSV':
                        type = 'to_csv';
                        break;
                }

                window.open(`${ process.env.VUE_APP_URL }/api/timecard_pdf/${ type }/${ employeeID }?${ helpers.getQSString(options)}&genid=${Math.floor(Math.random() * 101)}&params[token]=${store.state.object.data.token}`);
            },
            isCurrentView: function(view) {
                return view === this.state.currentView;
            }
        },
        computed: {
            currentView: function() {
                return this.state.currentView;
            }
        },
        watch: {
            id: function () {
                this.load();
            }
        }
    }
</script>

<style scoped>

</style>